<template>
  <div>
    <p>{{ $t('message.home.msg') }}</p>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      msg: 'Sorry, there is no content yet. Visit the Data tab to see something',
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
